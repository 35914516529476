import _, { toNumber } from 'lodash';
import {
  IEmission,
  icons,
  ICounty,
  IEmissionCategory,
} from '../../models/Models';
import Categories from '../../data/content/Categories.json';
import Modules from '../../data/content/Modules.json';
import Counties from '../../data/content/Counties.json';
import Constants from '../../data/content/Constants.json';
import { client } from '@sanity-config/lib/client';
import {
  getCategoryColor,
  getCategoryName,
  getCategoryParentId,
  getCategoryDescription,
  getCategoryIcon,
} from './HelperService';
import { Category } from '@/models/interfaces/Category';

const fetchLevel = (level: number | undefined) => {
  switch (level) {
    case 0:
      return 'top-level';
    case 1:
      return 'level-two';
    case 2:
      return 'level-three';
    default:
      return 'top-level';
  }
};

const parseEmissions = (emission: IEmission[], layer = 0) =>
  emission.map((x: IEmission) => {
    const hasDetails = layer !== 2;

    return {
      amount: x.amount ? parseFloat(x.amount.toFixed(4)) : 0,
      year: x.year ? x.year : 0,
      percentage: x.percentage ? x.percentage : 0,
      trend: x.trend ? x.trend : 0,
      hasDetails,
      category: {
        id: x.category.id ? x.category.id : '',
        parentId: x.category.parentId
          ? x.category.parentId
          : getCategoryParentId(x.category.id),
        name: x.category.name || getCategoryName(x.category.id),
        description: getCategoryDescription(x.category.id),
        icon: getCategoryIcon(x.category.id),
        color: getCategoryColor(x.category.id),
      },
      county: {
        id: x.county?.id ? x.county.id : '',
        name: x.county?.name ? x.county.name : '',
        emission: x,
        active: x.county?.active ? x.county.active : false,
      },
    };
  });

export const inferLayer = (
  cat1: string | undefined,
  cat2: string | undefined,
  cat3: string | undefined
) => {
  let layer = 0;
  if (cat3) {
    layer = 3;
  } else if (cat2) {
    layer = 2;
  } else if (cat1) {
    layer = 1;
  }
  return layer;
};

export const fetchCategories = async (
  category: string,
  layer: number,
  isCounty: boolean
) => {
  const baseUrl = Constants.services.apiUrl;
  let queryUrl = `${baseUrl}get-all-categories?layer=${layer}&isCounty=${isCounty}`;
  if (category) {
    queryUrl = queryUrl.concat(`&parentCategory=${category}`);
  }

  try {
    const resp = await fetch(queryUrl, {
      mode: 'no-cors',
      headers: {
        'Content-Type': 'application-json',
      },
      next: { tags: ['emissionsSSB'] },
    }).then((response) => {
      if (!response.ok) {
        throw new Error(response.statusText);
      }
      return response.json();
    });
    return resp;
  } catch (e) {
    console.error('Failed to fetch categories', e);
    throw new Error('Failed to fetch categories');
  }
};

export const fetchCategoryTree = async (isCounty: boolean) => {
  const baseUrl = Constants.services.apiUrl;
  const queryUrl = `${baseUrl}get-category-tree?isCounty=${isCounty}`;
  try {
    const resp = await fetch(queryUrl, {
      mode: 'no-cors',
      headers: {
        'Content-Type': 'application-json',
      },
      next: { tags: ['emissionsSSB', 'categories'] },
    }).then((response) => {
      if (!response.ok) {
        throw new Error(response.statusText);
      }
      return response.json();
    });
    return resp;
  } catch (e) {
    console.error('Failed to fetch categories', e);
    throw new Error('Failed to fetch categories');
  }
};

export const fetchMostRecentYear = async ({
  isCounty = false,
  isSubCategory = false,
}: {
  isCounty?: boolean;
  isSubCategory?: boolean;
}) => {
  const baseUrl = Constants.services.apiUrl;
  try {
    const resp = await fetch(
      `${baseUrl}get-most-recent-year?isCounty=${isCounty}&isSubCategory=${isSubCategory}`,
      {
        mode: 'no-cors',
        headers: {
          'Content-Type': 'application-json',
        },
        next: { tags: ['emissionsSSB', 'emissionsMDIR'] },
      }
    ).then((response) => {
      if (!response.ok) {
        throw new Error(response.statusText);
      }
      return response.json();
    });
    return resp;
  } catch (e) {
    console.error('Failed to fetch most recent year', e);
    throw new Error('Failed to fetch most recent year');
  }
};

export const fetchEmissions = async ({
  cat,
  subcat,
  layer = 0,
  year,
}: {
  cat?: string;
  subcat?: string;
  layer?: number;
  year?: number;
}) => {
  const baseUrl = Constants.services.apiUrl;
  const params = new URLSearchParams();

  if (cat) {
    params.append('categoryId', cat);
  }

  if (cat && subcat) {
    params.append('subCategoryId', subcat);
  }

  if (year) {
    params.append('year', year.toString());
  }

  const level = fetchLevel(layer);

  try {
    const resp = await fetch(`${baseUrl}${level}?${params}`, {
      next: {
        tags: ['emissionsSSB'],
      },
      mode: 'no-cors',
      headers: {
        'Content-Type': 'application-json',
      },
    }).then((response) => {
      if (!response.ok) {
        throw new Error(response.statusText);
      }
      return response.json();
    });

    return parseEmissions(resp, layer) as IEmission[];
  } catch (e) {
    console.error(`Failed to fetch emissions: ${e}`);
    throw new Error('Failed to fetch emissions');
  }
};

export const fetchParentEmissions = async ({
  cat,
  year,
  layer = 0,
}: {
  cat?: string;
  layer: number;
  year?: number;
}) => {
  const baseUrl = Constants.services.apiUrl;
  const params = new URLSearchParams();

  if (cat) {
    params.append('categoryId', cat);
  }

  if (year) {
    params.append('year', year.toString());
  }

  try {
    const resp = await fetch(`${baseUrl}get-category?${params}`, {
      next: {
        tags: ['emissionsSSB'],
      },
      mode: 'no-cors',
      headers: {
        'Content-Type': 'application-json',
      },
    }).then((response) => {
      if (!response.ok) {
        throw new Error(response.statusText);
      }
      return response.json();
    });
    return parseEmissions(resp, layer) as IEmission[];
  } catch (e) {
    console.error(`Failed to fetch parentEmission, params=${params}`, e);
    throw new Error('Failed to fetch parentEmission');
  }
};

export const fetchAllCategories = async ({
  layer = 1,
  parentCategory = '',
}: {
  layer: number;
  parentCategory?: string;
}) => {
  const query = `${Constants.services.apiUrl}get-all-categories?layer=${layer}${parentCategory ? `&parentCategory=${parentCategory}` : ''}`;

  const resp = await fetch(`${query}`, {
    next: {
      tags: ['emissionsMDIR'],
    },
    mode: 'no-cors',
    headers: {
      'Content-Type': 'application-json',
    },
  }).then((response) => {
    if (!response.ok) {
      throw new Error(response.statusText);
    }
    return response.json();
  });

  return resp as Category[];
};

export const fetchCountyEmissions = async ({
  county,
  year,
  layer = 0,
  category,
  subcategory,
}: {
  county: number;
  year?: number;
  layer?: number;
  category?: string;
  subcategory?: string;
}) => {
  const level = layer > 0 ? 'level-two' : 'top-level';
  const baseUrl = `${Constants.services.apiUrl}counties-details-${level}`;
  const countyParam = `?countyId=${county}`;
  const yearParam = year ? `&year=${year}` : '';
  const categoryParam = category ? `&parentCategoryId=${category}` : '';
  const subcategoryParam = subcategory ? `&categoryId=${subcategory}` : '';
  const query = `${baseUrl}${countyParam}${yearParam}${categoryParam}${subcategoryParam}`;

  try {
    const resp = await fetch(`${query}`, {
      next: {
        tags: ['emissionsMDIR'],
      },
      mode: 'no-cors',
      headers: {
        'Content-Type': 'application-json',
      },
    }).then((response) => {
      if (!response.ok) {
        throw new Error(response.statusText);
      }
      return response.json();
    });
    return parseEmissions(resp, 2) as IEmission[];
  } catch (e) {
    console.error('Failed to fetch county emissions', e);
    throw new Error('Failed to fetch county emissions');
  }
};

export const fetchNationalOrCountyEmissions = async ({
  category = 'ALL',
  parentCategory = '',
  layer = 0,
  county = 0,
  year = -1,
}: {
  category: string;
  parentCategory: string;
  layer: number;
  county: number;
  year?: number;
}) => {
  let emissions: IEmission[] = [];
  if (county) {
    // Fetch county data
    if (layer <= 1) {
      emissions = await fetchCountyEmissions({
        subcategory: category,
        layer: layer - 1,
        county,
        year,
      });
    } else {
      emissions = await fetchCountyEmissions({
        category: parentCategory,
        subcategory: category,
        layer: layer - 1,
        county,
        year,
      });
    }
  } else if (layer <= 1) {
    // No county specified, fetch national data for level 0 and 1
    emissions = await fetchEmissions({
      cat: category,
      layer: layer > 0 ? layer - 1 : 0,
    });
  } else {
    // No county specified, fetch national data for level >= 2
    emissions = await fetchEmissions({
      cat: parentCategory,
      subcat: category,
      layer: layer > 0 ? layer - 1 : 0,
    });
  }
  return emissions;
};

const parseCounties = (counties: ICounty[]) =>
  counties.map((county: ICounty) => ({
    name: county.name ? county.name : '',
    value: 0,
    active: false,
    id: county.id ? county.id : 0,
  }));

export const fetchAllCounties = async (
  allCountiesActive = true,
  addAllCounties = true
) => {
  try {
    const resp = await fetch(`${Constants.services.apiUrl}counties`, {
      mode: 'no-cors',
      headers: {
        'Content-Type': 'application-json',
      },
      next: { tags: ['emissionsMDIR'] },
    }).then((response) => {
      if (!response.ok) {
        throw new Error(response.statusText);
      }
      return response.json();
    });

    if (addAllCounties) {
      resp.push({ name: 'Hele landet', id: 0, active: allCountiesActive });
    }

    return parseCounties(resp) as ICounty[];
  } catch (e) {
    console.error('Failed to fetch counties', e);
    throw new Error('Failed to fetch counties');
  }
};

export const fetchAllCounties2024 = async (
  allCountiesActive = true,
  addAllCounties = true
) => {
  try {
    const resp = await fetch(`${Constants.services.apiUrl}counties-new`, {
      mode: 'no-cors',
      headers: {
        'Content-Type': 'application-json',
      },
      next: { tags: ['emissionsMDIR'] },
    }).then((response) => {
      if (!response.ok) {
        throw new Error(response.statusText);
      }
      return response.json();
    });

    if (addAllCounties) {
      resp.push({ name: 'Hele landet', id: 0, active: allCountiesActive });
    }

    return parseCounties(resp) as ICounty[];
  } catch (e) {
    console.error('Failed to fetch counties', e);
    throw new Error('Failed to fetch counties');
  }
};

export const fetchEmissionTexts = async ({
  layer,
  county,
  sanityQuery,
}: {
  layer: number;
  county: boolean;
  sanityQuery: string;
}) => {
  const result = await client.fetch(
    sanityQuery,
    {},
    { next: { tags: ['cmsText'] } }
  );
  const emissionsTexts = result[0];

  const hasData = (o: any) => {
    if (!o) return false;
    if (o.length < 1) return false;
    if (o === ' ') return false;
    return true;
  };

  let tekst = county
    ? emissionsTexts.tekstFylke || emissionsTexts.tekst
    : emissionsTexts.tekst;
  if (hasData(emissionsTexts.kategori)) {
    if (layer >= 1) {
      const kategori = emissionsTexts.kategori[0];
      if (hasData(kategori.tekst) || hasData(kategori.tekstFylke)) {
        tekst = county ? kategori.tekstFylke || kategori.tekst : kategori.tekst;
      }
    }
    if (layer >= 2) {
      if (hasData(emissionsTexts.kategori[0].kategori)) {
        const kategori = emissionsTexts.kategori[0].kategori[0];
        if (hasData(kategori.tekst) || hasData(kategori.tekstFylke)) {
          tekst = county
            ? kategori.tekstFylke || kategori.tekst
            : kategori.tekst;
        }
      }
    }
  }

  if (!hasData(tekst)) {
    tekst = county
      ? emissionsTexts.tekstFylke || emissionsTexts.tekst
      : emissionsTexts.tekst;
  }

  return { tekst, emissionsTexts };
};

export const getCountyFromId = (id: number, counties: ICounty[]) =>
  counties.find((c: ICounty) => c.id === toNumber(id));

export const parseCountiesAndEmissions = (
  c: ICounty[],
  e: IEmission[],
  activeCounty: number
) =>
  c.map((county: ICounty) => {
    const currentEmission = e.find(
      (emission) => emission.county?.id === county.id
    );

    return {
      name: county.name ? county.name : '',
      id: county.id ? county.id : 0,
      emission: currentEmission || null,
      active: activeCounty === county.id,
    };
  }) as ICounty[];

export const getHighestEmission = (emission: IEmission[], round = true) => {
  let highest = 0;

  emission.forEach((e: IEmission) => {
    if (e.amount > highest) {
      highest = e.amount;
    }
  });

  return round ? Math.round(highest) : highest;
};

export const getEmissionMeterMax = (emission: IEmission[]) => {
  const highestEmission = getHighestEmission(emission, false);
  let oldestEmission = 0;
  let oldestYear = 2050;

  emission.forEach((e: IEmission) => {
    if (e.year < oldestYear) {
      oldestYear = e.year;
      oldestEmission = e.amount;
    }
  });

  let maxClamp = oldestEmission;

  if (maxClamp >= highestEmission) {
    maxClamp += Constants.emissions.percentageIncrease * oldestEmission;
  } else if (maxClamp < highestEmission) {
    maxClamp = highestEmission;
    // Constants.emissions.percentageIncrease2 * oldestEmission; //TODO This does nothing?
  }

  maxClamp = Math.round(maxClamp);

  if (maxClamp <= highestEmission) {
    maxClamp = highestEmission + 1;
  }

  return Math.round(maxClamp);
};

export const getParentEmission = (
  emission: IEmission[],
  id: string,
  year: number
) => {
  const filteredEmissions = emission.filter((x) => x.year === year);

  const parentEmission = {
    amount: 0,
    year: 0,
    percentage: 0,
    trend: 0,
    hasDetails: false,
    category: {
      color: getCategoryColor(id),
      description: getCategoryDescription(id),
      icon: getCategoryIcon(id) as icons,
      id,
      name: getCategoryName(id),
    },
  };

  filteredEmissions.forEach((e: IEmission) => {
    parentEmission.amount += e.amount;
    parentEmission.year = e.year;
  });

  parentEmission.amount = parseFloat(parentEmission.amount.toFixed(2));

  return parentEmission as IEmission;
};

// ************************************
// Year Functionality
// ************************************

export const filterByYear = (emission: IEmission[], year: number) => {
  const filteredEmission: IEmission[] = [];

  emission.forEach((x: IEmission) => {
    if (x.year === year) {
      filteredEmission.push(x);
    }
  });

  return filteredEmission;
};

export const getTotalEmissionByYear = (emission: IEmission[], year: number) => {
  let total = 0;

  emission.forEach((e: IEmission) => {
    if (e.year === year && e.category.id === 'ALL') {
      total += e.amount;
    }
  });

  return parseFloat(total.toFixed(2));
};

export const getTotalEmissionsByYear = (emission: IEmission[]) => {
  let filteredEmission: IEmission[] = [];

  emission.forEach((x: IEmission) => {
    if (filteredEmission.length > 0) {
      const existingEm = filteredEmission.find(
        (em: IEmission) => x.year === em.year
      );

      if (existingEm) {
        existingEm.amount += x.amount;
      } else {
        filteredEmission.push(x);
      }
    } else {
      filteredEmission.push(x);
    }
  });

  filteredEmission = _.sortBy(filteredEmission, (x) => x.year);

  return filteredEmission;
};

export const getOldestYearData = (
  emissions: IEmission[],
  categoryId = 'ALL',
  ignoreCat = false
) => {
  let data: IEmission = {
    amount: 51.5,
    year: 1990,
    hasDetails: false,
    category: {
      id: 'ALL',
      name: 'I hele Norge',
    },
  };

  let current = 2050;

  emissions.forEach((x: IEmission) => {
    if (x.year < current && (x.category.id === categoryId || ignoreCat)) {
      current = x.year;
      data = x;
    }
  });

  return data;
};

export const getMostRecentYearData = (
  emissions: IEmission[],
  categoryId = 'ALL',
  ignoreCat = false
) => {
  let data: IEmission = {
    amount: 0,
    year: 2050,
    hasDetails: false,
    category: {
      id: 'ALL',
      name: 'Mål 2050',
    },
  };

  let current = 0;

  emissions.forEach((x: IEmission) => {
    if (x.year > current && (x.category.id === categoryId || ignoreCat)) {
      current = x.year;
      data = x;
    }
  });

  return data;
};

export const getTotalEmission = (emission: IEmission[]) => {
  let total = 0;
  emission.forEach((e) => {
    total += e.amount;
  });
  return total;
};

// ************************************
// Category Functionality
// ************************************

export const getSingleEmissionByCategory = (
  emissions: IEmission[],
  id: string
) => {
  let emission: IEmission | null | undefined = null;

  if (emissions) {
    emission = emissions.find((e) => e.category.id === id);
  }

  return emission;
};

export const getSingleEmissionByYear = (
  emissions: IEmission[],
  year: number,
  cat: string
) => {
  let emission: IEmission | null | undefined = null;

  if (emissions) {
    emission = emissions.find((e) => e.year === year && e.category.id === cat);
  }

  return emission;
};

export const getEmissionByCategory = (
  emissions: IEmission[],
  catId: string
) => {
  const mostRecentYear = getMostRecentYearData(emissions, '', true);
  const emission = emissions.find(
    (x) => x.category.id === catId && x.year === mostRecentYear.year
  );
  return emission || emissions[0];
};

export const getMainCategories = () => {
  const cats: any[] = [];

  Categories.cats.forEach((item) => {
    if (item.id) {
      switch (item.id) {
        case 'TRANSPORT':
        case 'AGRICULTURE':
        case 'OTHER':
        case 'INDUSTRY':
        case 'OIL_AND_GAS':
        case 'ENERGY_AND_HEATING':
          cats.push(item);
          break;
        default:
      }
    }
  });

  return cats;
};

export const parseCategory = (
  categoryId: string,
  categories: IEmissionCategory[]
) => {
  const findCategoryName = () =>
    categories.find((cat: IEmissionCategory) => cat.id === categoryId)?.name;
  const findCategoryDescription = () =>
    categories.find((cat: IEmissionCategory) => cat.id === categoryId)
      ?.description;
  return {
    id: categoryId ?? '',
    name: findCategoryName() || getCategoryName(categoryId) || '',
    description:
      findCategoryDescription() || getCategoryDescription(categoryId),
    icon: getCategoryIcon(categoryId),
    color: 'black',
  } as IEmissionCategory;
};

// ************************************
// Category Functionality :: Modules
// ************************************

export const getFactDataFromCategory = (catID: string) => {
  const items = Modules.factsBlock.categories.find(
    (x) => x.id === catID
  )?.items;
  const hasItems = !!(items && items.length > 0);

  return {
    title: Modules.factsBlock.title,
    items,
    hasItems,
  };
};

// ************************************
// County Functionality
// ************************************

export const getCountyNameFromCategory = (countyId: number) => {
  const name = Counties.counties.find((x) => x.id === countyId)?.name;

  return name || '';
};
