import {
  getCategoryIcon,
  getCategoryImage,
  getCategoryName,
} from './HelperService';

import Constants from '../../data/content/Constants.json';
import { Vehicle } from '../../models/Models';

// ************************************
// Properties / Interfaces
// ************************************

interface IFetchConfig {
  callType:
    | 'new'
    | 'stock'
    | 'stock-emissionfree-year'
    | 'new-emissionfree-year'
    | 'new-emissionfree-month'
    | 'by-county';
  county?: number;
  emissionStatus?: number;
  group?: string | null | undefined;
}

const parseVehicleData = (data: Vehicle) => {
  if (data) {
    return {
      category: {
        id: data.category?.id ? data.category.id : '',
        name: getCategoryName(data.category.id),
        emissionFree: data.category.emissionFree,
        icon: getCategoryIcon(data.category.id),
        image: getCategoryImage(data.category.id),
      },
      county: {
        id: data.county.id,
        name: data.county.name,
      },
      totalAmount: data.totalAmount,
      amount: data.amount,
      totalAmountByCounty: data.totalAmountByCounty,
      percentageOfTotal: data.percentageOfTotal,
      year: data.year,
      month: data.month,
    } as Vehicle;
  }
  return null;
};

const parseVehicleListData = (data: Vehicle[]) => {
  if (data.length > 0) {
    return data.map((e: Vehicle) => parseVehicleData(e)) as Vehicle[];
  }
  return [];
};

export const fetchVehiclesByCategory = async (
  county: number,
  category: string
) => {
  try {
    const baseUrl = Constants.services.apiUrl;
    const query = `vehicles-by-category?countyid=${county}&category=${category}`;

    const r = await fetch(`${baseUrl}${query}`, {
      mode: 'no-cors',
      headers: {
        'Content-Type': 'application-json',
      },
      next: { tags: ['vehicles'] },
    }).then((response) => {
      if (!response.ok) {
        throw new Error(response.statusText);
      }
      return response.json();
    });

    const parsedResponse = {
      totalNew: parseVehicleData(r.totalNew),
      totalStock: parseVehicleData(r.totalStock),
      vehiclesNewByYear: parseVehicleListData(r.vehiclesNewByYear),
      vehiclesNewByMonth: parseVehicleListData(r.vehiclesNewByMonth),
      vehiclesNewByRegion: parseVehicleListData(r.vehiclesNewByRegion),
      vehiclesStockByYear: parseVehicleListData(r.vehiclesStockByYear),
      vehiclesStockByMonth: parseVehicleListData(r.vehiclesStockByMonth),
      vehiclesStockByRegion: parseVehicleListData(r.vehiclesStockByRegion),
    };

    return parsedResponse;
  } catch (e) {
    console.error(
      `Failed to fetch vehicles by category. county: ${county} category: ${category}`,
      e
    );
    throw new Error('Failed to fetch vehicles by category');
  }
};

export const fetchYearlyVehiclesByCategory = async (
  county: number,
  category: string
) => {
  try {
    const baseUrl = Constants.services.apiUrl;
    const query = `vehicles-stock-emissionfree-year?countyid=${county}&vehiclegroup=${category}`;

    const r = await fetch(`${baseUrl}${query}`, {
      mode: 'no-cors',
      headers: {
        'Content-Type': 'application-json',
      },
      next: { tags: ['vehicles'] },
    }).then((response) => {
      if (!response.ok) {
        throw new Error(response.statusText);
      }
      return response.json();
    });

    return r;
  } catch (e) {
    console.error(
      `Failed to fetch vehicles by category. county: ${county} category: ${category}`,
      e
    );
    throw new Error('Failed to fetch yearly vehicles by category');
  }
};

export const fetchVehiclesByCounty = async (county = -1) => {
  const baseUrl = Constants.services.apiUrl;
  const query = `vehicles-by-county?countyid=${county}`;

  try {
    const r = await fetch(`${baseUrl}${query}`, {
      next: { tags: ['vehicles'] },
      mode: 'no-cors',
      headers: {
        'Content-Type': 'application-json',
      },
    }).then((response) => {
      if (!response.ok) {
        throw new Error(response.statusText);
      }
      return response.json();
    });

    const parsedResponse = {
      totalNew: parseVehicleData(r.totalNew),
      totalStock: parseVehicleData(r.totalStock),
      vehiclesNew: parseVehicleListData(r.vehiclesNew),
      vehiclesNewByMonth: parseVehicleListData(r.vehiclesNewByMonth),
      vehiclesStock: parseVehicleListData(r.vehiclesStock),
      vehiclesStockByMonth: parseVehicleListData(r.vehiclesStockByMonth),
    };

    return parsedResponse;
  } catch (e) {
    console.error(`Failed to fetch vehicles by county. county: ${county}`, e);
    throw new Error('Failed to fetch vehicles by county');
  }
};

export const fetchVehicleDataFront = async () => {
  const baseUrl = Constants.services.apiUrl;

  try {
    const resp = await fetch(
      `${baseUrl}vehicles-stock?countyid=0&emissionstatus=1&vehiclegroup=ALL`,
      {
        next: { tags: ['vehicles'] },
        mode: 'no-cors',
        headers: {
          'Content-Type': 'application-json',
        },
      }
    ).then((response) => {
      if (!response.ok) {
        throw new Error(response.statusText);
      }
      return response.json();
    });
    return parseVehicleListData(resp);
  } catch (e) {
    console.error(`Failed to fetch vehicle data: ${e}. param: stock`);
    throw new Error('Failed to fetch vehicle data');
  }
};

export const fetchVehicleData = async (config: IFetchConfig) => {
  const { callType, county = -1, group, emissionStatus = -1 } = config;

  const baseUrl = Constants.services.apiUrl;
  let query = `vehicles-${callType}`;
  const queryParams: string[] = [];

  if (county > -1) {
    queryParams.push(`countyid=${county}`);
  }

  if (group) {
    queryParams.push(`vehiclegroup=${group}`);
  }

  if (emissionStatus > -1) {
    queryParams.push(`emissionstatus=${emissionStatus}`);
  }

  if (queryParams.length > 0) {
    query += '?';

    queryParams.forEach((param: string, index: number) => {
      query += param;

      if (queryParams[index + 1]) {
        query += '&';
      }
    });
  }

  try {
    const resp = await fetch(`${baseUrl}${query}`, {
      mode: 'no-cors',
      headers: {
        'Content-Type': 'application-json',
      },
      next: { tags: ['vehicles'] },
    }).then((response) => {
      if (!response.ok) {
        throw new Error(response.statusText);
      }
      return response.json();
    });
    return parseVehicleListData(resp);
  } catch (e) {
    console.error(`Failed to fetch vehicle data: ${e}. param: ${config}`);
    throw new Error('Failed to fetch vehicle data');
  }
};

// ************************************
// Calculation
// ************************************

export const calculateAverageVehicleGrowth = (vehicles: Vehicle[]) => {
  let average = 0;

  vehicles.forEach((v: Vehicle, i: number) => {
    if (i + 1 < vehicles.length) {
      const change = vehicles[i + 1].percentageOfTotal - v.percentageOfTotal;
      average += change;
    }
  });

  return average / vehicles.length;
};

export const combineNewAndStockForCards = (
  stockData: Vehicle[],
  newData: Vehicle[]
) => {
  const categories = Constants.vehiclesDataSettings.categoryGroups;

  return categories.map((c: string) => {
    const newVehicle: any = newData.find((v: Vehicle) => v.category.id === c);
    const stockVehicle: any = stockData.find(
      (v: Vehicle) => v.category.id === c
    );

    return {
      new: newVehicle,
      stock: stockVehicle,
    };
  });
};
