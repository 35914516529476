/** @type {import('tailwindcss').Config} */
module.exports = {
  content: [
    './app/**/*.{js,jsx,ts,tsx}',
    './src/modules/**/*.{js,jsx,ts,tsx}',
    './src/components/**/*.{js,jsx,ts,tsx}',
  ],
  theme: {
    container: {
      center: true,
      padding: '2rem',
      screens: {
        '2xl': '1400px',
      },
    },
    extend: {
      typography: () => ({
        DEFAULT: {
          css: {
            color: '#000000',
            h1: {
              fontSize: '40px',
              fontWeight: '700',
              lineHeight: '3.25rem',
              margin: 0,
            },
            h2: {
              fontSize: '30px',
              fontWeight: '700',
              lineHeight: '2.4rem',
              margin: 0,
            },
            h3: {
              fontSize: '24px',
              fontWeight: '700',
              lineHeight: '1.95rem',
              margin: 0,
            },
            h4: {
              fontSize: '18px',
              fontWeight: '700',
              lineHeight: '1.46rem',
              margin: 0,
            },
            h5: {
              fontSize: '16px',
              fontWeight: '700',
              lineHeight: '1.3rem',
              margin: 0,
            },
            // Body large from the design system
            p: {
              fontSize: '18px',
              fontWeight: '400',
              lineHeight: '1.68rem',
              marginBottom: '1rem',
            },
            a: {
              fontWeight: '400',
              lineHeight: '1.68rem',
              textDecoration: 'underline',
              '&:hover': {
                backgroundColor: 'black',
                fontWeight: '700',
                color: 'white',
                transition: 'all 0.3s ease-in-out', // Adjust the duration as needed
              },
            },
          },
        },
        lg: {
          css: {
            h1: {
              fontSize: '56px',
              lineHeight: '4.55rem',
              margin: 0,
            },
            h2: {
              fontSize: '42px',
              lineHeight: '3.41rem',
              margin: 0,
            },
            h3: {
              fontSize: '32px',
              lineHeight: '2.6rem',
              margin: 0,
            },
            h4: {
              fontSize: '20px',
              lineHeight: '1.625rem',
              margin: 0,
            },
            h5: {
              fontSize: '18px',
              lineHeight: '1.46rem',
              margin: 0,
            },
          },
        },
        xl: {
          css: {
            h1: {
              fontSize: '72px',
              lineHeight: '5.85rem',
              margin: 0,
            },
            h2: {
              fontSize: '56px',
              lineHeight: '4.55rem',
              margin: 0,
            },
            h3: {
              fontSize: '36px',
              lineHeight: '3.25rem',
              margin: 0,
            },
            h4: {
              fontSize: '24px',
              lineHeight: '1.95rem',
              margin: 0,
            },
            h5: {
              fontSize: '20px',
              lineHeight: '1.625rem',
              margin: 0,
            },
            // Body large from the design system
            p: {
              fontSize: '20px',
              lineHeight: '1.875rem',
              marginBottom: '1rem',
            },
          },
        },
      }),
      colors: {
        blue: 'var(--color-blue)',
        'blue-light': 'var(--color-blue-light)',
        'blue-very-light': 'var(--color-blue-very-light)',
        'blue-bright': 'var(--color-blue-bright)',
        purple: 'var(--color-purple)',
        'purple-bright': 'var(--color-purple-bright)',
        green: 'var(--color-green)',
        'green-very-light': 'var(--color-green-very-light)',
        'green-super-light': 'var(--color-green-super-light)',
        'green-faded': 'var(--color-green-faded)',
        'green-extra-faded': 'var(--color-green-extra-faded)',
        'green-super-faded': ' var(--color-green-super-faded)',
        'green-bright': 'var(--color-green-bright)',
        white: 'var(--color-white)',
        beige: 'var(--color-beige)',
        black: 'var(--color-black)',
        'dark-grey': 'var(--color-dark-grey)',
        pink: 'var(--color-pink)',
        'pink-light': 'var(--color-pink-light)',
        'pink-very-light': 'var(--color-pink-very-light)',
        'pink-bright': 'var(--color-pink-bright)',
        yellow: 'var(--color-yellow)',
        'yellow-light': 'var(--color-yellow-light)',
        'yellow-faded': 'var(--color-yellow-faded)',
        'yellow-bright': 'var(--color-yellow-bright)',
        red: 'var(--color-red)',
        'red-light': 'var(--color-red-light)',
        'red-faded': 'var(--color-red-faded)',
        'red-bright': 'var(--color-red-bright)',
        'brown-very-light': 'var(--color-brown-very-light)',
        'new-yellow-very-light': 'var(--color-new-yellow-very-light)',
        'new-yellow-light': 'var(--color-new-yellow-light)',
        'new-green-super-light': 'var(--color-new-green-super-light)',
        'light-grey': 'var(--color-light-grey)',
        border: 'hsl(var(--border))',
        input: 'hsl(var(--input))',
        ring: 'hsl(var(--ring))',
        background: 'hsl(var(--background))',
        foreground: 'hsl(var(--foreground))',
        primary: {
          DEFAULT: 'hsl(var(--primary))',
          foreground: 'hsl(var(--primary-foreground))',
        },
        secondary: {
          DEFAULT: 'hsl(var(--secondary))',
          foreground: 'hsl(var(--secondary-foreground))',
        },
        destructive: {
          DEFAULT: 'hsl(var(--destructive))',
          foreground: 'hsl(var(--destructive-foreground))',
        },
        muted: {
          DEFAULT: 'hsl(var(--muted))',
          foreground: 'hsl(var(--muted-foreground))',
        },
        accent: {
          DEFAULT: 'hsl(var(--accent))',
          foreground: 'hsl(var(--accent-foreground))',
        },
        popover: {
          DEFAULT: 'hsl(var(--popover))',
          foreground: 'hsl(var(--popover-foreground))',
        },
        card: {
          DEFAULT: 'hsl(var(--card))',
          foreground: 'hsl(var(--card-foreground))',
        },
        // Sector colors
        total_emissions: '#39565B',
        transport: '#377EB8',
        oil_and_gas: '#C65300',
        industry: '#8E3FD2',
        agriculture: '#7B482A',
        energy_and_heating: '#A47000',
        other: '#D461B3',
        waste: '#D461B3',
      },
      borderRadius: {
        lg: 'var(--radius)',
        md: 'calc(var(--radius) - 2px)',
        sm: 'calc(var(--radius) - 4px)',
      },
      keyframes: {
        'accordion-down': {
          from: { height: '0' },
          to: { height: 'var(--radix-accordion-content-height)' },
        },
        'accordion-up': {
          from: { height: 'var(--radix-accordion-content-height)' },
          to: { height: '0' },
        },
      },
      animation: {
        'accordion-down': 'accordion-down 0.2s ease-out',
        'accordion-up': 'accordion-up 0.2s ease-out',
      },
    },
  },
  future: {
    hoverOnlyWhenSupported: true,
  },
  // eslint-disable-next-line import/no-extraneous-dependencies, global-require
  plugins: [
    require('@tailwindcss/typography'),
    require('tailwindcss-animate'),
    require('tailwind-scrollbar-hide'),
  ],
};
