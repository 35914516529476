import {
  getColorCode,
  Theme,
  getFontFamily,
  getFontSize,
  getSpacing,
} from '../../models/Models';
import Modules from '../../data/content/Modules.json';
import { tNumber } from './HelperService';
import {
  ChartOptions,
  CreditsOptions,
  ExportingOptions,
  LegendOptions,
  TitleOptions,
  TooltipOptions,
  XAxisOptions,
  YAxisOptions,
} from 'highcharts';

export const chartLangDefault = () => ({
  shortMonths: [
    'Jan',
    'Feb',
    'Mar',
    'Apr',
    'Mai',
    'Jun',
    'Jul',
    'Aug',
    'Sep',
    'Okt',
    'Nov',
    'Des',
  ],
  months: [
    'Januar',
    'Februar',
    'Mars',
    'April',
    'Mai',
    'Juni',
    'Juli',
    'August',
    'September',
    'Oktober',
    'November',
    'Desember',
  ],
  weekdays: [
    'Søndag',
    'Mandag',
    'Tirsdag',
    'Onsdag',
    'Torsdag',
    'Fredag',
    'Lørdag',
  ],
  decimalPoint: ',',
});

export const chartTooltipDefault = (theme: Theme, measurment: string) =>
  ({
    backgroundColor:
      theme === Theme.Dark
        ? `${getColorCode('white')}`
        : `${getColorCode('black')}`,
    borderColor: `${getColorCode('transparent')}`,
    style: {
      color:
        theme === Theme.Dark
          ? `${getColorCode('black')}`
          : `${getColorCode('white')}`,
    },
    borderRadius: 16,
    useHTML: true,
    outside: true,
    animation: false,
    formatter: function defaultFormatter() {
      if (
        this.point.series.name === Modules.clusterGraphBlockOne.series.goal &&
        this.point.x === 2030
      ) {
        return `<b>${this.point.series.name}</b><br />${tNumber(
          this.point.y,
          3
        )} ${measurment}`;
      }
      if (
        this.point.series.name ===
        Modules.clusterGraphBlockOne.series.todaysTrend
      ) {
        return `<b>${this.point.series.name} i ${
          this.point.x
        }</b><br /> ${tNumber(this.point.y, 3)} ${measurment}`;
      }
      if (this.point && this.point.name) {
        return `<b>${this.point.name}</b> <br />
        ${tNumber(this.point.y, 3)} ${measurment}`;
      }

      if (this.point && !this.point.name) {
        return `<b>${this.point.series.name}</b> <br />
        ${tNumber(this.point.y, 3)} ${measurment}`;
      }

      return false;
    },
  }) satisfies TooltipOptions;

export const chartExportDefault = () =>
  ({
    chartOptions: {
      legend: {
        enabled: true,
        itemStyle: {
          color: `black`,
          fontSize: '1rem',
        },
      },
      chart: {
        backgroundColor: `#ffffff`,
      },
    },
    enabled: true,
    fallbackToExportServer: true,
    filename: 'Chart Data',
    buttons: {
      contextButton: {
        theme: {
          fill: `black`,
          stroke: `black`,
        },
        symbolStroke: `white`,
        menuItems: [
          'downloadCSV',
          'seperator',
          'downloadPNG',
          'downloadJPEG',
          'downloadSVG',
        ],
        x: 0,
        y: -30,
      },
    },
  }) satisfies ExportingOptions;

export const chartLegendDefault = {
  enabled: false,
  useHTML: true,
  rtl: true,
  symbolRadius: 4,
  alignColumns: false,
  floating: true,
  margin: 0,
  itemMarginBottom: 0,
  padding: 0,
  layout: 'vertical',
  verticalAlign: 'top',
  align: 'right',
} satisfies LegendOptions;

export const chartChartDefault = (
  type: string,
  width?: number | null,
  height?: number | null
) =>
  ({
    backgroundColor: undefined,
    plotBackgroundColor: undefined,
    plotBorderWidth: undefined,
    plotShadow: false,
    width,
    height,
    type,
    style: {
      overflow: 'visible',
    },
  }) satisfies ChartOptions;

export const chartTitleDefault = () =>
  ({
    text: undefined,
  }) satisfies TitleOptions;

export const chartCreditsDefaults = () =>
  ({
    enabled: false,
  }) satisfies CreditsOptions;

export const chartYAxisDefault = (yAxis: any, lineColor: string) => {
  let titleText = yAxis.title;

  if (yAxis.type === 'percent') {
    titleText = '%';
  }

  return {
    title: {
      text: titleText,
      align: 'low',
      rotation: 0,
      x: 0,
      y: 40,
      style: {
        color: getColorCode('black'),
        fontSize: '1rem',
        fontFamily: getFontFamily(),
        fontWeight: '400',
      },
    },
    labels: {
      useHTML: true,
      style: {
        color: getColorCode('black'),
        fontSize: getFontSize('text-small'),
        fontFamily: getFontFamily(),
        fontWeight: '400',
        lineHeight: getSpacing('small-spacing'),
      },
      align: 'left',
    },
    lineColor,
    gridLineColor: lineColor,
    min: 0,
    max: yAxis?.type === 'percent' ? 100 : null,
  } satisfies YAxisOptions;
};

export const chartXAxisDefault = (
  categories: string[] | undefined,
  lineColor: string
) =>
  ({
    title: undefined,
    labels: {
      style: {
        color: getColorCode('black'),
        fontSize: getFontSize('text-small'),
        fontFamily: getFontFamily(),
        fontWeight: '400',
        lineHeight: 18,
        width: 60,
      },
      formatter: undefined,
    },
    type: 'category',
    categories,
    lineColor,
    gridLineColor: lineColor,
    dateTimeLabelFormats: {
      millisecond: '%H:%M:%S.%L',
      second: '%H:%M:%S',
      minute: '%H:%M',
      hour: '%H:%M',
      day: '%d. %b',
      week: '%d. %b',
      month: '%B %Y',
      year: '%Y',
    },
  }) satisfies XAxisOptions;
