import CATEGORIES from '../data/content/Categories.json';
import CONFIGURATIONS from '../data/content/Configurations.json';
import COUNTIES from '../data/content/Counties.json';
import LANGUAGE from '../data/content/Language.json';
import MODULES from '../data/content/Modules.json';
import PAGES from '../data/content/Pages.json';
import CONSTANTS from '../data/content/Constants.json';

interface ApplicationConfig {
  D: any;
  Modules: any;
  Pages: any;
  Config: any;
  Cats: any;
  Counties: any;
  Constants: any;
}

const useApplicationConfig = () =>
  ({
    D: LANGUAGE,
    Modules: MODULES,
    Pages: PAGES,
    Config: CONFIGURATIONS,
    Cats: CATEGORIES.cats,
    Counties: COUNTIES.counties,
    Constants: CONSTANTS,
  }) satisfies ApplicationConfig;

export default useApplicationConfig;
