import Categories from '../../data/content/Categories.json';
import Counties from '../../data/content/Counties.json';
import D from '../../data/content/Language.json';

import {
  icons,
  ICounty,
  IEmissionCategory,
  Trend,
  vehicleCategory,
} from '../../models/Models';

import resolveConfig from 'tailwindcss/resolveConfig';
import tailwindConfig from '../../../tailwind.config';
import { DefaultColors } from 'tailwindcss/types/generated/colors';
const fullConfig = resolveConfig(tailwindConfig);

type Colors = DefaultColors & {
  total_emissions: string;
  transport: string;
  oil_and_gas: string;
  industry: string;
  agriculture: string;
  energy_and_heating: string;
  other: string;
};
const configColors = fullConfig.theme?.colors as Colors;

// ************************************
// Interfaces :: Local
// ************************************

interface ICategoryConfiguration {
  toLowerCase?: boolean;
  plural?: boolean;
}

// ************************************
// General
// ************************************

const months = [
  'Januar',
  'Februar',
  'Mars',
  'April',
  'Mai',
  'Juni',
  'Juli',
  'August',
  'September',
  'Oktober',
  'November',
  'Desember',
];

const monthsShort = [
  'Jan',
  'Feb',
  'Mar',
  'Apr',
  'Mai',
  'Jun',
  'Jul',
  'Aug',
  'Sep',
  'Okt',
  'Nov',
  'Des',
];

// eslint-disable-next-line no-confusing-arrow
export const getMonthName = (month: number, lowercase = false) =>
  lowercase ? months[month].toLocaleLowerCase() : months[month];

// eslint-disable-next-line no-confusing-arrow
export const getShortMonthName = (month: number, lowercase = false) =>
  lowercase ? monthsShort[month].toLocaleLowerCase() : monthsShort[month];

export const kFormatter = (num: number) => {
  const reduced = Math.round(Math.abs(num) / 100) / 10;

  return Math.abs(num) > 999
    ? `${Math.sign(num) * reduced}k`
    : Math.sign(num) * Math.abs(num);
};

export const getPercentage = (of: number, total: number) => {
  let percentage = 0;

  percentage = (of / total) * 100;

  percentage = Number.isNaN(percentage) ? 0 : percentage;
  percentage = percentage > 100 ? 100 : percentage;
  percentage = percentage < 0 ? 0 : percentage;

  return parseFloat(percentage.toFixed(2));
};

export const tNumber = (
  value: number | undefined,
  significantDigits = 10,
  language = 'no-NO'
) => {
  if (value === undefined) {
    return '';
  }

  return Intl.NumberFormat(language, {
    maximumSignificantDigits: significantDigits,
    maximumFractionDigits: 2,
  }).format(value);
};

// ************************************
// Categories
// ************************************

export const findNestedCategory = (
  categoryTree: IEmissionCategory[],
  categoryId: string
): IEmissionCategory | null => {
  for (const category of categoryTree) {
    if (category.id === categoryId) {
      return category;
    }

    if (category.subCategories) {
      const found = findNestedCategory(category.subCategories, categoryId);
      if (found) {
        return found;
      }
    }
  }
  return null;
};

export const getCategoryParentId = (categoryID: string) => {
  const catParentId = Categories.cats.find(
    (x) => x.id === categoryID
  )?.parentId;

  return catParentId || '';
};

export const getCategoryIcon = (categoryID: string) => {
  const catIcon = Categories.cats.find((x) => x.id === categoryID)?.icon;

  return catIcon ? (catIcon as icons) : ('other' as icons);
};

export const getCategoryColor = (categoryID: string) => {
  const catColor = Categories.cats.find((x) => x.id === categoryID)?.color;

  return catColor || 'black';
};

type Sectors = {
  total_emissions: string;
  transport: string;
  oil_and_gas: string;
  industry: string;
  agriculture: string;
  energy_and_heating: string;
  industry_oil_and_gas: string;
  other: string;
};

export type Sector =
  | 'total_emissions'
  | 'transport'
  | 'oil_and_gas'
  | 'industry'
  | 'agriculture'
  | 'energy_and_heating'
  | 'industry_oil_and_gas'
  | 'other';

// Return the sector colors defined in the Tailwind config
export const getSectorColors = () => {
  return {
    total_emissions: configColors['total_emissions'],
    transport: configColors['transport'],
    oil_and_gas: configColors['oil_and_gas'],
    industry: configColors['industry'],
    agriculture: configColors['agriculture'],
    energy_and_heating: configColors['energy_and_heating'],
    industry_oil_and_gas: configColors['industry'],
    waste: configColors['other'],
    other: configColors['other'],
  } as Sectors;
};

const isSector = (input: string): input is Sector => {
  const sectorKeys = Object.keys(getSectorColors());
  return sectorKeys.includes(input);
};

export const getSectorColor = (sector: Sector | string) => {
  const sectorColors = getSectorColors();
  return isSector(sector) ? sectorColors[sector] : 'bg-black';
};

// Helper function for converting hex colors to rgba values
export function hexToRGBA(hex: string, alpha: number): string {
  const r = parseInt(hex.slice(1, 3), 16);
  const g = parseInt(hex.slice(3, 5), 16);
  const b = parseInt(hex.slice(5, 7), 16);

  return `rgba(${r}, ${g}, ${b}, ${alpha})`;
}

export const getCategoryName = (
  id: string,
  config: ICategoryConfiguration = { toLowerCase: false, plural: false }
) => {
  const { toLowerCase, plural } = config;

  let catName = Categories.cats.find((x) => x.id === id)?.name;

  catName = catName || '';
  catName = toLowerCase ? catName.toLocaleLowerCase() : catName;
  catName = plural ? `${catName}er` : catName;

  return catName;
};

export const getCategoryDescription = (categoryID: string) => {
  const catDes = Categories.cats.find((x) => x.id === categoryID)?.description;

  return catDes || '';
};

export const getCategoryImage = (categoryID: string) => {
  const catImage = Categories.cats.find((x) => x.id === categoryID)?.icon;

  return catImage
    ? (catImage as vehicleCategory)
    : ('KJØRETØY' as vehicleCategory);
};

export const getCountyName = (county: ICounty, useCountyName = false) => {
  let name = Counties.counties.find((x) => x.id === county.id)?.name;

  if (useCountyName && county.id === 0) {
    name = D.general.norway;
  }

  return name || '';
};

export const getTrendFromPercentage = (percentage: number) => {
  if (percentage > 0) {
    return Trend.High;
  }
  if (percentage < 0) {
    return Trend.Low;
  }
  return Trend.Flat;
};
