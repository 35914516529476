/* eslint-disable no-shadow */
import _ from 'lodash';
import {
  IEmission,
  getColorCode,
  ICounty,
  Vehicle,
  IEmissionCategory,
} from '../../models/Models';
import {
  getOldestYearData,
  getMostRecentYearData,
} from './EmissionDataService';
import { calculateFirstGoalAbsoluteEstimate } from './EmissionsCalculator';
import { calculateAverageVehicleGrowth } from './VehicleService';

import Modules from '../../data/content/Modules.json';
import Config from '../../data/content/Configurations.json';
import D from '../../data/content/Language.json';
import Constants from '../../data/content/Constants.json';

import { getCategoryName } from './HelperService';
import {
  PointOptionsObject,
  SeriesAreaOptions,
  SeriesColumnOptions,
  SeriesLineOptions,
  SeriesOptionsType,
  SeriesPieOptions,
} from 'highcharts';

// ************************************
// Pie
// ************************************

export const parsePieSeries = (emission: IEmission[], year: number) => {
  const series: SeriesPieOptions[] = [
    {
      type: 'pie',
      name: `${year}`,
      data: [],
      shadow: false,
    },
  ];

  emission.forEach((e: IEmission, i: number) => {
    if (e.year === year && e.category.id !== 'ALL') {
      series[0].data?.push({
        name: e.category.name,
        y: e.percentage,
        x: i,
        color: getColorCode(e.category.color ? e.category.color : 'black'),
      });
    }
  });

  return series as SeriesPieOptions[];
};

export const parseSmallPieSeries = (
  emission: IEmission[],
  category: string
) => {
  const series: SeriesPieOptions[] = [
    {
      type: 'pie',
      data: [],
    },
  ];

  if (category === 'ALL') {
    emission.forEach((e: IEmission, i: number) => {
      if (e.category.id !== 'ALL') {
        series[0].data?.push({
          name: e.category.name,
          y: e.amount,
          x: i,
          color: '#D75A00',
        });
      }
    });
  } else {
    emission.forEach((e: IEmission, i: number) => {
      if (e.category.id !== 'ALL') {
        series[0].data?.push({
          name: e.category.name,
          y: e.amount,
          x: i,
          color: e.category.id === category ? '#D75A00' : '#8F8F8F',
          sliced: e.category.id === category,
        });
      }
    });
  }

  return series;
};

// ************************************
// Line
// ************************************

export const parseLineModuleSeries = (
  emission: IEmission[],
  categories: IEmissionCategory[]
) => {
  const series: SeriesLineOptions[] = [];

  emission.forEach((x: IEmission) => {
    const categoryName =
      x.category.name ||
      categories.find((cat: IEmissionCategory) => cat.id === x.category.id)
        ?.name;
    if (categoryName !== 'Alle utslipp') {
      const foundExistingSeries = series.find((s) => s.name === categoryName);

      const dataPoint = {
        name: x.year.toString(),
        x: x.year,
        y: x.amount,
      };

      if (foundExistingSeries) {
        foundExistingSeries.data?.push(dataPoint);
      } else {
        series.push({
          type: 'line',
          name: categoryName,
          data: [dataPoint],
        });
      }
    }
  });

  return series;
};

// ************************************
// Column 1
// ************************************

export const parseColumnModuleSeries = (counties: ICounty[]) => {
  const series: SeriesColumnOptions[] = [
    {
      type: 'column',
      colorByPoint: true,
      data: [],
    },
  ];

  if (counties && counties.length > 0) {
    counties.forEach((c: ICounty, i: number) => {
      if (c.id !== 0) {
        const color = c.active
          ? getColorCode('blue')
          : getColorCode('blue-very-light');

        series[0].data?.push({
          name: c.name,
          x: i,
          y: c.emission?.amount,
          color,
        });
      }
    });
  }

  return series;
};

// ************************************
// Area 1
// ************************************

const generateTrendData = (
  emission: IEmission[],
  present: IEmission,
  goal: IEmission
) => {
  const past = emission.find((emission) => emission.year === present.year - 3);
  if (!past) {
    throw new Error('No emission data found for the specified year');
  }
  const trend = (present.amount - past.amount) / (present.year - past.year);
  const trendData = [];

  if (trend <= 0) {
    let { year } = present;
    while (true) {
      const i = year - present.year;
      const trendEstimate = present.amount + trend * i;
      if (trendEstimate < goal.amount || year > goal.year + 49) {
        trendData.push({ name: year.toString(), x: year, y: trendEstimate });
        break;
      }

      trendData.push({ name: year.toString(), x: year, y: trendEstimate });
      year++;
    }
  } else {
    for (let i = 0; present.year + i <= goal.year + 49; i++) {
      const year = present.year + i;
      const trendEstimate = present.amount + trend * i;
      trendData.push({ name: year.toString(), x: year, y: trendEstimate });
    }
  }
  return trendData;
};

export const parseAreaModuleGoalSeries = (
  emission: IEmission[],
  goalFraction: number,
  year: number,
  county: boolean,
  color: string
) => {
  const series = [] as (SeriesAreaOptions | SeriesLineOptions)[];

  const present = getMostRecentYearData(emission, '', true);

  // Past Data
  series.push({
    name: 'Historiske tall',
    linkedTo: 'histData',
    type: 'line',
    lineWidth: 5,
    showInLegend: false,
    zIndex: 3,
    data: [],
    marker: {
      enabled: false,
    },
  });

  series.push({
    name: 'Historiske tall',
    id: 'histData',
    type: 'line',
    lineWidth: 2,
    dashStyle: 'Solid',
    data: [],
    color: color,
    marker: {
      symbol: 'circle',
      enabled: true,
    },
  });

  if (emission && emission.length > 0) {
    emission.forEach((x: IEmission) => {
      series[0].data?.push({
        name: x.year.toString(),
        x: x.year,
        y: x.amount,
      });
    });
  }

  const goal = { amount: 0, year } as IEmission;
  const oldYear = emission.reduce((min, e) => (e.year < min.year ? e : min));

  if (oldYear) {
    goal.amount = oldYear.amount * goalFraction;
  }
  // 2030 / total

  // Trend Estimates for each year
  const trendData = generateTrendData(emission, present, goal);
  series.push({
    name: Modules.clusterGraphBlockOne.series.todaysTrend,
    type: 'line',
    lineWidth: 5,
    linkedTo: 'trendData',
    showInLegend: false,
    zIndex: 2,
    marker: {
      symbol: 'square',
      enabled: false,
    },
    data: [
      {
        name: present.year.toString(),
        x: present.year,
        y: present.amount,
      },
      ...trendData,
    ],
  });

  series.push({
    name: Modules.clusterGraphBlockOne.series.todaysTrend,
    id: 'trendData',
    type: 'line',
    lineWidth: 2,
    dashStyle: 'Solid',
    data: [],
    color: '#808080',
    marker: {
      symbol: 'square',
      enabled: true,
    },
  });

  // Goal calculation for each year
  const startEmission = emission[emission.length - 1];
  const trendGoal =
    (emission[0].amount * goalFraction - startEmission.amount) /
    (goal.year - startEmission.year);
  const trendGoalDataPrYear = Array.from(
    { length: goal.year - startEmission.year },
    (_, i) => {
      const year = startEmission.year + i;
      const trendEstimate =
        startEmission.amount + trendGoal * (year - startEmission.year);
      return {
        name: year.toString(),
        x: year,
        y: trendEstimate,
      };
    }
  );

  const trendName = `${goal.year}-målet`;

  if (!county) {
    series.push({
      name: trendName,
      type: 'line',
      lineWidth: 3,
      dashStyle: 'Dash',
      color: '#000000',
      marker: {
        symbol: 'circle',
      },
      zIndex: 2,
      data: [
        {
          name: startEmission.year.toString(),
          x: startEmission.year,
          y: startEmission.amount,
        },
        ...trendGoalDataPrYear,
        {
          name: goal.year.toString(),
          x: goal.year,
          y: emission[0].amount * goalFraction,
        },
      ],
    });
  }

  return series;
};

// ************************************
// Line Area
// ************************************

export const parseLineAreaModuleSeries = (
  emission: IEmission[],
  layer: number
) => {
  const series = [] as (SeriesAreaOptions | SeriesLineOptions)[];
  const past = getOldestYearData(emission);
  const present = getMostRecentYearData(emission, '', true);

  // Past Data
  series.push({
    name: `${past.year}-${present.year}`,
    type: 'line',
    lineWidth: 2,
    zIndex: 3,
    data: [],
  });

  if (emission && emission.length > 0) {
    emission.forEach((x: IEmission) => {
      series[0].data?.push({
        name: x.year.toString(),
        x: x.year,
        y: x.amount,
      });
    });
  }

  // 2030, the array in Configurations.json
  // must match the alignment of the active layer
  const goal = Config.goals[layer];

  if (emission !== null && emission.length > 0) {
    const twentyThirtyGoal = emission[0].amount * 0.5; // target for 2030 is 50% of the 1990 figures
    if (twentyThirtyGoal > 0) {
      goal.amount = twentyThirtyGoal;
    }
  }

  // Trend calculations
  const revEmissions = _.sortBy(emission, (e) => e.year).reverse();
  const threeYearTrend = calculateFirstGoalAbsoluteEstimate(revEmissions);

  // Trend Estimates
  series.push({
    name: Modules.clusterGraphBlockOne.series.todaysTrend,
    type: 'line',
    lineWidth: 2,
    zIndex: 1,
    data: [
      {
        name: present.year.toString(),
        x: present.year,
        y: present.amount,
      },
      {
        name: goal.year.toString(),
        x: goal.year,
        y: threeYearTrend,
      },
    ],
  });

  // Goal
  series.push({
    name: Modules.clusterGraphBlockOne.series.goal,
    type: 'line',
    lineWidth: 3,
    zIndex: 2,
    dashStyle: 'Dash',
    data: [
      {
        name: present.year.toString(),
        x: present.year,
        y: present.amount,
      },
      {
        name: goal.year.toString(),
        x: goal.year,
        y: emission[0].amount * 0.45,
      },
    ],
  });

  return series;
};

// ************************************
// Point
// ************************************

export const parsePointGraphData = (emission: IEmission[]) => {
  const series: SeriesLineOptions[] = [
    {
      type: 'line',
      name: '',
      data: [],
    },
  ];

  for (let i = emission.length - 1; i > emission.length - 6; i--) {
    series[0].data?.push({
      name: emission[i].year.toString(),
      x: emission[i].year,
      y: emission[i].amount,
    });
  }

  series[0].data?.sort((a, b) => {
    if (a === undefined || b === undefined) {
      return 0;
    }

    if (typeof a === 'number' && typeof b === 'number') {
      return a - b;
    }

    if (a instanceof Array && b instanceof Array) {
      const aX = a[0] as number;
      const bX = b[0] as number;
      return aX - bX;
    }

    const aX = (a as PointOptionsObject).x as number;
    const bX = (b as PointOptionsObject).x as number;
    return aX - bX;
  });

  return series;
};

// ************************************
// Area 2
// ************************************

export const parseAreaVehicleSeriesPercent = (
  vehicleData: Vehicle[],
  addTotal: boolean,
  only12Months: boolean
) => {
  const series: SeriesOptionsType[] = [];
  const serie1: SeriesOptionsType = {
    name: D.vehicles.notEmissionFree,
    type: 'area',
    data: [],
  };
  const serie2: SeriesOptionsType = {
    name: D.vehicles.emissionFree,
    type: 'area',
    data: [],
  };
  const categories: string[] = [];

  vehicleData.forEach((v: Vehicle) => {
    const date = new Date(v.year, v.month - 1);
    const timeString = date.getTime();
    const dateString = date.toLocaleDateString('nb-NO', {
      month: 'long',
      year: 'numeric',
    });
    serie1.data?.push({
      y: 100,
      name: dateString,
      x: timeString,
    });
    serie2.data?.push({
      y: v.percentageOfTotal,
      name: dateString,
      x: timeString,
    });
  });
  // Only show the last 12 months
  if (only12Months) {
    serie1.data = serie1.data?.slice(-12);
    serie2.data = serie2.data?.slice(-12);
  }
  if (addTotal) {
    series.push(serie1);
  }
  series.push(serie2);
  return { series, categories };
};

export const parseAreaVehicleSeriesAmount = (
  vehicleData: Vehicle[],
  addTotal: boolean,
  only12Months: boolean
) => {
  const series: SeriesOptionsType[] = [];
  const serie1: SeriesOptionsType = {
    name: D.vehicles.notEmissionFree,
    type: 'area',
    color: {
      pattern: {
        color: '#8F8F8F',
        path: {
          d: 'M 0 0 L 10 10 M 9 -1 L 11 1 M -1 9 L 1 11',
          strokeWidth: 4.5,
        },
        width: 10,
        height: 10,
      },
    },
    data: [],
  };
  const serie2: SeriesOptionsType = {
    name: D.vehicles.emissionFree,
    type: 'area',
    data: [],
  };
  const categories: string[] = [];

  vehicleData.forEach((v: Vehicle) => {
    const date = new Date(v.year, v.month - 1);
    const timeString = date.getTime();
    const dateString = date.toLocaleDateString('nb-NO', {
      month: 'long',
      year: 'numeric',
    });
    serie1.data?.push({
      y: v.totalAmountByCounty - v.amount,
      name: dateString,
      x: timeString,
    });
    serie2.data?.push({
      y: v.amount,
      name: dateString,
      x: timeString,
    });
  });
  // Only show the last 12 months
  if (only12Months) {
    serie1.data = serie1.data?.slice(-12);
    serie2.data = serie2.data?.slice(-12);
  }
  if (addTotal) {
    series.push(serie1);
  }
  series.push(serie2);
  return { series, categories };
};

// ************************************
// Area 3
// ************************************

export const parseAreaListItem = (data: Vehicle[]) => {
  const seriesRegistered: SeriesAreaOptions = {
    name: '',
    type: 'area',
    data: [],
  };
  const seriesEstimated: SeriesAreaOptions = {
    name: D.vehicles.trend,
    type: 'area',
    data: [],
  };

  if (data && data.length > 0) {
    const sortedData: Vehicle[] = _.orderBy(data, ['year'], ['asc']);
    let lowestValue = 100;
    let highestValue = 0;

    seriesRegistered.name = `${sortedData[0].year}-${
      sortedData[sortedData.length - 1].year
    }`;

    sortedData.forEach((vehicle: Vehicle) => {
      if (vehicle.percentageOfTotal > highestValue) {
        highestValue = vehicle.percentageOfTotal;
      }

      if (vehicle.percentageOfTotal < lowestValue) {
        lowestValue = vehicle.percentageOfTotal;
      }

      seriesRegistered.data?.push([vehicle.year, vehicle.percentageOfTotal]);
    });

    const length = seriesRegistered.data?.length || 0;
    const lastRegistered = seriesRegistered.data?.[length - 1] as [
      number,
      number,
    ];

    const lastRegisteredYear = lastRegistered?.[0];
    let lastRegisteredValue = lastRegistered?.[1];

    const average = calculateAverageVehicleGrowth(sortedData);

    for (let i = lastRegisteredYear; i < Config.goalYearLimit + 1; i++) {
      if (i !== lastRegisteredYear) {
        lastRegisteredValue += average;
        lastRegisteredValue =
          lastRegisteredValue > 100 ? 100 : lastRegisteredValue;
      }
      seriesEstimated.data?.push([i, lastRegisteredValue]);
    }
  }

  return [seriesEstimated, seriesRegistered];
};

// ************************************
// Column 2
// ************************************

const getCountyIndex = (counties: ICounty[], id: number) => {
  let foundIndex = 1;

  counties.forEach((c: ICounty, index: number) => {
    if (c.id === id) {
      foundIndex = index;
    }
  });

  return foundIndex;
};

export const parseColumnChartSeries = (
  counties: ICounty[],
  data: Vehicle[],
  category = 'ALL'
) => {
  const filteredCounties = counties.filter((c: ICounty) => c.id !== 0);
  const countyNames: string[] = filteredCounties.map((c: ICounty) => c.name);
  const catGroups = Constants.vehiclesDataSettings.limitedCategoryGroups;
  const series: SeriesColumnOptions[] = [];

  if (category === 'ALL') {
    catGroups.forEach((cat: string) => {
      series.push({
        id: cat,
        type: 'column',
        name: getCategoryName(cat),
        data: filteredCounties.map(() => 0),
        borderWidth: 0,
      });
    });
  } else {
    series.push({
      id: category,
      type: 'column',
      name: getCategoryName(category),
      data: filteredCounties.map(() => 0),
      borderWidth: 0,
    });
  }

  if (data.length > 0) {
    series.forEach((s) => {
      data.forEach((d: Vehicle) => {
        if (d.category.id === s.id) {
          if (d.percentageOfTotal > 50) {
            // s.yAxis = 100; // TODO why is this logic here? causes chart to break in vehicle page
          }

          s.data?.splice(
            getCountyIndex(filteredCounties, d.county.id),
            1,
            d.percentageOfTotal
          );
        }
      });
    });
  }

  return {
    categories: countyNames,
    series,
  };
};
