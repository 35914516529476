import { createClient } from '@sanity/client';

import { apiVersion, dataset, projectId, readToken, useCdn } from '../env';

// eslint-disable-next-line import/prefer-default-export
export const client = createClient({
  apiVersion,
  dataset,
  projectId,
  useCdn,
  token: readToken,
});
