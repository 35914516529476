import EmissionBannerImage from '../assets/bannerImages/emission-banner-image.svg';

/* eslint-disable import/no-unused-modules */
export * from './services/EmissionDataService';
export * from './services/EmissionsCalculator';
export * from './services/ChartService';
export * from './services/HelperService';
export * from './services/VehicleService';
export * from './services/ChartStylingService';

export const getEmissionBarBannerImage = () => EmissionBannerImage;
