'use client';

import { useMemo } from 'react';
import classnames from 'classnames/bind';
import { Theme, themeToString } from '../../models/Models';

import CustomLink from '../customLink/CustomLink';
import styles from './Card.module.scss';
import { UrlObject } from 'url';

interface IProps {
  id?: string;
  className?: string;
  children?: React.ReactNode | string;
  useScrollOverflow?: boolean;
  theme: Theme;
  disabled?: boolean;
  link?: UrlObject | string | null;
  gridStyle?:
    | 'row'
    | 'column-center'
    | 'column-start'
    | 'column-space-between'
    | 'block';
  onClick?: (value: any) => void | null;
}

const Card = (props: IProps) => {
  // ************************************
  // Properties
  // ************************************

  const componentClass = 'card';
  const {
    id,
    className,
    children,
    theme,
    disabled = true,
    useScrollOverflow = false,
    link,
    gridStyle = 'column-center',
    onClick,
  } = props;
  const cx = classnames.bind(styles);

  const getContent = useMemo(
    () => (
      <div
        className={cx(`${componentClass}__viewport`, {
          [`${componentClass}__viewport--overflow`]: useScrollOverflow,
          [`${componentClass}__viewport--${gridStyle}`]: gridStyle,
        })}
      >
        <div
          className={cx(`${componentClass}__container`, {
            [`${componentClass}__container--overflow`]: useScrollOverflow,
            [`${componentClass}__container--${gridStyle}`]: gridStyle,
          })}
        >
          {children}
        </div>
      </div>
    ),
    [cx, useScrollOverflow, gridStyle, children]
  );

  return (
    <CustomLink
      id={id}
      className={cx(componentClass, {
        [`${componentClass}--${themeToString(theme)}`]: theme !== null,
        [`${componentClass}--clickable`]: !disabled && theme !== Theme.Locked,
        [`${className}`]: className,
      })}
      onClick={onClick}
      disabled={disabled}
      to={link || '#'}
      element={getContent}
    />
  );
};

export default Card;
