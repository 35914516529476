import { Trend, type IEmission } from '../../models/Models';
import Config from '../../data/content/Configurations.json';
import Constants from '../../data/content/Constants.json';
import { getMostRecentYearData } from './EmissionDataService';

const limit = 10000;

const trendComparator = (currentEmission: number, pastEmission: number) => {
  if (currentEmission > pastEmission) {
    return Trend.High;
  }
  if (currentEmission === pastEmission) {
    return Trend.Flat;
  }
  if (currentEmission < pastEmission) {
    return Trend.Low;
  }

  return Trend.Flat;
};

export const getTrend = (emission: number, comparativeValue: number) =>
  trendComparator(emission, comparativeValue);

export const calculateFirstGoalAbsoluteEstimate = (emissions: IEmission[]) => {
  let threeYearTrend = 0;

  for (let i = 0; i < Constants.emissions.estimationYearClamp; i++) {
    const subtractedAmount = emissions[i].amount - emissions[i + 1].amount;
    threeYearTrend += subtractedAmount;
  }

  threeYearTrend /= Constants.emissions.estimationYearClamp;
  threeYearTrend *= Config.goals[0].year - emissions[0].year;
  threeYearTrend += emissions[0].amount;
  threeYearTrend = parseFloat(threeYearTrend.toFixed(2));

  return threeYearTrend;
};

export const calculateFirstGoalPercentageEstimate = (
  emissions: IEmission[]
) => {
  let threeYearTrend = 0;

  for (let i = 0; i < Constants.emissions.estimationYearClamp; i++) {
    const subtractedAmount = emissions[i].amount / emissions[i + 1].amount;
    threeYearTrend += subtractedAmount;
  }

  threeYearTrend /= Constants.emissions.estimationYearClamp;
  threeYearTrend **= Config.goals[0].year - emissions[0].year;
  threeYearTrend *= emissions[0].amount;
  threeYearTrend = parseFloat(threeYearTrend.toFixed(2));

  return threeYearTrend;
};

export const calculateGoal = (
  emission: IEmission[],
  goalFraction: number,
  goalYear: number
) => {
  const present = getMostRecentYearData(emission, '', true);
  const trendGoal =
    (emission[0].amount * goalFraction - present.amount) /
    (goalYear - present.year);

  const trendEstimate = present.amount + trendGoal * (goalYear - present.year);
  return {
    name: goalYear,
    x: goalYear,
    y: trendEstimate,
  };
};

export const calculateYearReachingGoal = (
  emission: IEmission[],
  goalFraction: number,
  goalYear: number
) => {
  const present = getMostRecentYearData(emission, '', true);
  const past = emission.find((e) => e.year === present.year - 3);

  if (!past) {
    throw new Error('No emission data found for the specified year');
  }

  const trend = (present.amount - past.amount) / (present.year - past.year);

  const isDecreasing = trend < 0;
  const goalEmission = emission[0].amount * goalFraction;
  let currentEstimate = {
    year: present.year,
    estimate: present.amount,
    isDecreasing,
  };
  if (isDecreasing) {
    let { year } = present;
    while (true) {
      const i = year - present.year;
      const trendEstimate = present.amount + trend * i;
      if (trendEstimate <= goalEmission || year > limit) {
        currentEstimate = {
          year,
          estimate: trendEstimate,
          isDecreasing,
        };
        return currentEstimate;
      }
      currentEstimate = {
        year,
        estimate: trendEstimate,
        isDecreasing,
      };
      year++;
    }
  } else {
    const i = limit - present.year;
    const trendEstimate = present.amount + trend * i;
    return { year: goalYear, estimate: trendEstimate, isDecreasing };
  }
};
